import React from 'react';
import styled from 'styled-components';

function BulkProductListPage(props) {
  return <Wrapper>BulkProductList</Wrapper>;
}

const Wrapper = styled.div``;

export default BulkProductListPage;
